




























































































import Vue from 'vue';
import { estimationByConstructId } from '@/api/projectDetail';
import { mapGetters } from 'vuex';
import { setTableHeaderWidth } from '@/utils';

const provisionalEstimateColumns = [
  {
    dataIndex: 'dispNo',
    key: 'dispNo',
    align: 'center',
    width: 70,
    slots: { title: 'customTitle' },
    customCell: (record) => {
      if (record.checked) {
        return { style: { background: '#E6F6FF' } };
      } else {
        return { style: { background: '#F5F7F9' } };
      }
    },
  },
  {
    title: '暂估价名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: 200,
  },
  {
    title: '规格型号',
    dataIndex: 'attr',
    key: 'attr',
    scopedSlots: { customRender: 'attr' },
    align: 'center',
    width: 200,
  },
  {
    title: '单位',
    dataIndex: 'unit',
    key: 'unit',
    scopedSlots: { customRender: 'unit' },
    align: 'center',
  },
  {
    title: '单价(元)',
    dataIndex: 'total',
    key: 'total',
    scopedSlots: { customRender: 'total' },
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'options',
    key: 'options',
    scopedSlots: { customRender: 'options' },
    align: 'center',
  },
];

export default Vue.extend({
  name: 'tenderProvisionalEstimate',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    currentInfo: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      provisionalEstimateColumns,
      dialogData: [],
      loading: false,
      hintVisible: false,
      employerInfo: null,
      employerPrice: '',
      componentHeight: 0,
      tableHeadHeight: 0,
    };
  },
  computed: {
    ...mapGetters(['projectSequenceNbr']),
    tableScroll() {
      const num: number = this.componentHeight - this.tableHeadHeight - 3;
      return { y: num, x: 'max-content' };
    },
  },
  watch: {
    visible: function (val) {
      if (val) {
        this.estimationByConstructId();
        this.setComponentHeight();
        window.addEventListener('resize', this.getTableHeaderWidth);
      } else {
        window.removeEventListener('resize', this.getTableHeaderWidth, false);
      }
    },
  },
  methods: {
    getContainer() {
      return document.getElementById('ysf_body');
    },
    getTableHeaderWidth() {
      setTableHeaderWidth('.folder-list');
    },
    hide() {
      // const obj = {
      //   sequenceNbr: this.currentInfo.sequenceNbr,
      //   donorMaterialName: '',
      //   donorMaterialPrice: '',
      // };
      // if (this.currentInfo.donorMaterialName) {
      //   this.$emit('updateData', obj);
      // }
      this.$emit('update:visible', false);
    },
    proving() {
      this.employerPrice = (this.employerPrice.match(
        /\d{0,8}(\.\d{0,2}|100)?/
      ) || [''])[0];
      if (
        (this.employerInfo as any).price !== 0 &&
        this.employerPrice === '0'
      ) {
        this.$message.error('招标暂估价格不可为0');
        this.employerPrice = '';
        return;
      }
    },
    updatePrice() {
      const obj = {
        sequenceNbr: this.currentInfo.sequenceNbr,
        materialSequenceNbr: (this.employerInfo as any).sequenceNbr,
        provisionalEstimateName: (this.employerInfo as any).name,
        provisionalEstimatePrice:
          this.currentInfo.unit !== (this.employerInfo as any).unit
            ? this.employerPrice
            : (this.employerInfo as any).total,
      };
      if (!obj.provisionalEstimatePrice) {
        this.$message.error('请输入招标暂估价格');
        return;
      }
      this.hintVisible = false;
      this.$emit('updateData', obj);
      this.$emit('update:visible', false);
    },
    setComponentHeight() {
      this.$nextTick(() => {
        const tenderHeaderEl = this.$refs.headerInfo as HTMLDivElement;
        const tenderHeaderHeight = tenderHeaderEl.clientHeight;
        this.componentHeight = window.innerHeight - tenderHeaderHeight - 56; // 总投标的高度-头部标题高度 - marginTop
        const tableEl = document.querySelector(
          '.ant-table-thead'
        ) as HTMLElement;

        let tableHeadHeight = 0;
        if (tableEl) {
          tableHeadHeight = tableEl.clientHeight;
        }
        console.log(
          '111111111',
          window.innerHeight,
          tenderHeaderHeight,
          this.componentHeight
        );
        this.tableHeadHeight = tableHeadHeight;
      });
    },
    containerChange() {
      return document.getElementsByClassName('tender-provisional-estimate')[0];
    },
    /**
     * 点击 对应 按钮操作
     * @param record
     */
    choiceData(record) {
      this.employerInfo = record;
      this.employerPrice = this.currentInfo.provisionalEstimatePrice;
      if (
        this.currentInfo.unit !== record.unit ||
        this.currentInfo.provisionalEstimateName
      ) {
        this.hintVisible = true;
      } else if (!this.currentInfo.provisionalEstimateName) {
        this.updatePrice();
      }
    },
    /**
     * 招标文件暂估数据列表
     */
    estimationByConstructId() {
      this.loading = true;
      estimationByConstructId(this.projectSequenceNbr).then((res) => {
        if (res.status === 200 && res.result) {
          this.loading = false;
          this.dialogData = res.result;
          let bodyEl;
          let scrollTop = 0;
          if (this.currentInfo && this.currentInfo.materialSequenceNbr) {
            this.dialogData.forEach((item: any, index) => {
              if (item.sequenceNbr === this.currentInfo.materialSequenceNbr) {
                item.checked = true;
                bodyEl = (this.$refs.table as any).$children[0].$children[0]
                  .$children[0].$children[0].$children[0].$children[0]
                  .$children[1];
                scrollTop =
                  bodyEl.$children[0].$children[index + 1].$el.offsetTop;
                console.log('当前文件', scrollTop);
              } else {
                item.checked = false;
              }
            });
            (
              document.querySelector(
                '.folder-list .ant-table-body'
              ) as HTMLDivElement
            ).scrollTop = scrollTop;
          }
          this.getTableHeaderWidth();
        }
      });
    },
    rowClassName(record) {
      if (
        record.sequenceNbr === this.currentInfo.provisionalEstimateSequenceNbr
      ) {
        return 'checked-row';
      }
    },
  },
});
